//
//
//
//
//
//
//
//
//
//
//
//

import ArrowBase from '@/components/v2/span/ArrowBase.vue'

export default {
  name: 'CtaButton',
  components: {
    ArrowBase,
  },
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    textPath: {
      type: String,
      required: true,
      default: '',
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
    clasSpan: {
      type: String,
      default: '',
    },
    maxButton: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },

  methods: {
    scrollToAnchor() {
      const element = document.querySelector(this.textPath)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
  },
}
