//
//
//
//
//
//

import viewport from '~/plugins/viewport.js'
export default {
  name: 'ArrowBase',
  mixins: [viewport],
  props: {
    clasSpan: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
      // validator: (value) => ['>', '->', '-']?.includes(value),
      validator: (value) =>
        value === '' || value === '>' || value === '->' || '-',
    },
  },

  data() {
    return {
      autoClass: this.type === '' ? 'auto' : 'static', // Инициализация на основе пропса
    }
  },
  computed: {
    classSpan() {
      if (this.type === '>') {
        return 'triangular-right'
      } else if (this.type === '->' || this.type === '-') {
        return 'arrow-right'
      } else {
        return this.viewportWidth <= 768 ? 'arrow-right' : 'triangular-right'
      }
    },
  },
  watch: {
    type(newVal) {
      this.autoClass = this.type === '' ? 'auto' : 'static' // при изменение
    },
  },
}
